/*** Header styles ***/
#header { padding-top: 16px; }
#header .top { margin-bottom: 16px; }
#header .menu-actions { display: none; }
#header .menu-actions button { border: 1px solid #ffd700; border-radius: 8px; margin: 0; }
#header .menu-actions svg { display: block; width: 24px; height: 24px; }
#header .logo { display: block; width: 80px; height: 80px; padding: 0; border: 0; margin: 0; text-indent: -9999px; background-color: transparent; background-repeat: no-repeat; background-size: 100% auto; }
#header #switcher { display: block; float: right; list-style: none; padding: 0; margin: 0; }
#header #switcher li { float: left; padding: 0; margin: 0 8px 0 0; }
#header #switcher li a { display: block; width: 68px; height: 48px; border: 4px solid transparent; opacity: 0.8; text-indent: -9999px; background-repeat: no-repeat; background-size: cover; background-position: left top; transition: opacity 0.4s linear; }
#header #switcher li a:hover,
#header #switcher li a:focus { opacity: 1; }
#header #switcher li.current-lang a { border: 4px solid #ffd700; }

/*** Header responsive styles ***/
@media (max-width: 767px) {
	#header { position: fixed; left: 0; top: 0; right: 0; z-index: 10; padding-top: 0; border-bottom: 1px solid #ffd700; background: white; }
	#header .panel { border: 0; border-radius: 0; margin-bottom: 0; }
	#header .left-container { display: flex; align-items: center; justify-content: space-between; }
	#header .menu-actions { display: block; }
	#header .switcher-container { display: none; }
	#header .navigation { display: none; }
}
