/*** General form styles ***/
.general-form label { display: inline-flex; align-items: center; width: 100%; min-height: 1px; margin-bottom: 8px; text-align: unset; cursor: pointer; }
.general-form label.smaller { min-height: 1px; margin-bottom: 8px; }
.general-form input { display: inline-block; width: 100%; padding: 6px 8px; border: 1px solid transparent; border-bottom: 4px solid #ffd700; border-radius: 4px 4px 0 0; outline: none; margin-bottom: 16px; vertical-align: middle; background: transparent; transition: border 0.4s linear, margin 0.4s linear, color 0.4s linear, background 0.4s linear; }
.general-form input:hover,
.general-form input:focus { border-color: #ffd700; border-bottom-color: black; background: white; }
.general-form textarea { display: inline-block; width: 100%; min-height: 120px; padding: 6px 8px; border: 1px solid transparent; border-bottom: 4px solid #ffd700; border-radius: 4px 4px 0 0; resize: none; outline: none; margin-bottom: 16px; background: transparent; transition: border 0.4s linear, margin 0.4s linear, color 0.4s linear, background 0.4s linear; }
.general-form textarea:hover,
.general-form textarea:focus { border-color: #ffd700; border-bottom-color: black; background: white; }
.general-form-error { /*display: block;*/ margin-top: -16px; margin-bottom: 16px; color: red; }
