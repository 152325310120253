/*** Project styles ***/
.project .icon-cog { width: 48px; height: 48px; margin: 0 auto 16px auto; }
.project .information { padding-bottom: 16px; border-bottom: 1px solid #ffd700; margin-bottom: 16px; }
.project .information span { display: inline-block; }
.project .information .version { font-weight: bold; }
.project .information .delimiter { display: inline-block; margin: 0 8px; }
.project .download { padding-bottom: 16px; border-bottom: 1px solid #ffd700; margin-bottom: 16px; }
.project .download .label { display: inline-block; font-weight: bold; }
.project .download a { margin-left: 8px; text-transform: capitalize; }
.project .download .download-android svg { height: 58px; margin: -9px; }
.project .download .download-ios svg { height: 40px; }

/*** Project responsive styles ***/
@media (max-width: 767px) {
	.project .information { text-align: center; }
	.project .information span { width: 100%; margin-bottom: 8px; }
	.project .information span:last-child { margin-bottom: 0; }
	.project .information .delimiter { display: none; }
	.project .download { text-align: center; }
	.project .download .label { width: 100%; margin-bottom: 8px; }
	.project .download a { margin: 0 8px 0 0; }
	.project .download a:last-child { margin-right: 0; }
}
