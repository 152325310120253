/*** Global ***/
.flash { font-weight: bold; padding: 16px; border: 1px solid black; border-radius: 8px; font-size: 20px; }
.flash.info { color: orange; border-color: orange; }
.flash.error { color: red; border-color: red; }
.flash.success { color: green; border-color: green; }

h1,
h2,
h3,
h4 { margin: 0 0 16px 0; font-weight: 800; }
h1 { font-size: 24px; }
h2 { font-size: 22px; }
h3 { font-size: 20px; }
p { margin: 0 0 16px 0; line-height: 1.5; }
ul { list-style: circle; padding: 0 0 0 40px; margin-bottom: 16px; }
ul li { margin-bottom: 8px; }
.smalltext { font-size: 14px; }

img { display: inline-block; max-width: 100%; height: auto; }

.row { margin-left: -16px; margin-right: -16px; }
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto { padding-left: 16px; padding-right: 16px; }
.f-right { float: right; }
.hidden { display: none; }

.panel { padding: 16px 16px 0 16px; border: 1px solid #ffd700; border-radius: 8px; margin-bottom: 16px; background: white; }

a { display: inline-block; border-bottom: 1px solid #ffd700; margin-bottom: -1px; color: #ffd700; text-decoration: none; transition: border 0.4s linear, margin 0.4s linear, color 0.4s linear, background 0.4s linear; }
a:hover,
a:focus { border-bottom: 4px solid black; margin-bottom: -4px; color: #ffd700; text-decoration: none; }
a.no-fancy { border-bottom: none; margin-bottom: 0; }
a.no-fancy:hover,
a.no-fancy:focus { border-bottom: none; margin-bottom: 0; }
a.button,
button,
input[type=submit] { display: inline-block; width: auto; height: auto; min-height: 40px; padding: 6px 8px; border: 1px solid transparent; border-bottom: 4px solid #ffd700; border-radius: 4px 4px 0 0; outline: 0; margin: 0 0 16px 0; color: black; font-size: 18px; font-weight: bold; background: transparent; cursor: pointer; transition: border 0.4s linear, color 0.4s linear, background 0.4s linear; }
a.button.icon,
button.icon,
input[type=submit].icon { display: inline-flex; justify-content: center; align-items: center; width: 48px; height: 48px; }
a.button.active,
button.active,
input.button.active { border-color: #ffd700; border-bottom-color: black; }
a.button:hover,
button:hover,
input[type=submit]:hover,
a.button:focus,
button:focus,
input[type=submit]:focus { color: white; border-color: #ffd700; border-bottom-color: black; outline: 0; background: #ffd700; }
a.button svg,
button svg,
input[type=submit] svg { display: block; width: 18px; height: 18px; padding: 2px; margin: 0; }
a.button.icon-larger svg,
button.icon-larger svg,
input[type=submit].icon-larger svg { width: 28px; height: 28px; }

.navigation { display: flex; flex-wrap: wrap; }
.navigation .nav-link-container,
.navigation button { flex: 0 0 auto; margin: 0 8px 16px 0; }
.navigation .nav-link-container .button { margin: 0; }

/*** App styles ***/
#app { display: flex; flex: 1 0 auto; flex-direction: column; max-width: 100%; }
#app .menu { display: block; position: fixed; overflow: hidden; overflow-y: auto; width: 100%; height: calc(100% - 113px); top: 113px; left: -100%; z-index: 11; padding: 16px; background: white; transition: left 0.2s linear; }
#app .menu.open { left: 0; }
#app .menu .nav-link-container .button { width: 100%; }
#app .menu #switcher-mobile { display: flex; align-items: center; justify-content: center; list-style: none; padding: 0; margin: 0; }
#app .menu #switcher-mobile li { float: left; padding: 0; margin: 0 8px 0 0; }
#app .menu #switcher-mobile li a { display: block; width: 68px; height: 48px; border: 4px solid transparent; opacity: 0.8; text-indent: -9999px; background-repeat: no-repeat; background-size: cover; background-position: left top; transition: opacity 0.4s linear; }
#app .menu #switcher-mobile li a:hover,
#app .menu #switcher-mobile li a:focus { opacity: 1; }
#app .menu #switcher-mobile li.current-lang a { border: 4px solid #ffd700; }

/*** GDPR Categories page ***/
.chyly-gdpr-cookies-categories-marketing { display: none; }
#chyly-gdpr-cookies-categories-form .form-actions { margin-bottom: 16px; }

/*** App responsive styles ***/
@media (max-width: 767px) {
	body { font-size: 16px; }

	h1 { font-size: 22px; text-align: center; }
	h2 { font-size: 20px; }
	h3 { font-size: 18px; }
	ul { padding-left: 24px; }

	.flash { font-size: 18px; }

	#content { padding-top: 129px; }

	.navigation .nav-link-container,
	.navigation button { width: 100%; }
}

@media (max-width: 575px) {
	img { width: 100%; }
}
