/*** General popup styles ***/
.general-popup-container { /*display: none;*/ position: fixed; width: 100%; height: 100%; top: 0; left: 0; z-index: 100; align-items: center; justify-content: center; background: rgba(0, 0, 0, 0.6); }
/*.general-popup-container.visible,
.general-popup-container.animating { display: flex; }*/
.general-popup { display: flex; overflow: hidden; width: 90%; max-width: 480px; height: 90%; max-height: 320px; flex-direction: column; padding: 0; border: 0 solid #ffd700; border-bottom: 4px solid #ffd700; border-radius: 4px 4px 0 0; background: white; }
.general-popup.auto,
.auto .general-popup { height: auto; }
.gallery .general-popup { width: 100%; max-width: 100%; height: 100%; max-height: 100%; }
.red .general-popup { border-color: red; }
.general-popup-header { display: block; width: 100%; height: auto; padding: 8px 16px; margin: 0; text-align: center; background: #ffd700; }
.red .general-popup-header { background: red; }
.general-popup-header h2 { margin: 0; color: black; text-transform: uppercase; }
.gallery .general-popup-header h2 { text-transform: none; }
.general-popup-content { display: block; flex-grow: 1; width: 100%; height: auto; overflow: hidden; overflow-y: auto; padding: 16px 16px 0 16px; }
.gallery .general-popup-content { padding-bottom: 16px; background: #f5f5f5; }
.general-popup-content p { text-align: justify; }
.gallery .general-popup-content img { display: block; max-height: 100%; margin: auto; }
.general-popup-footer { display: block; width: 100%; height: auto; padding: 8px 16px; border-top: 0 solid black; margin: 0; background: transparent; }
.red .general-popup-footer { border-top-color: red; }
.gallery .general-popup-footer { display: flex; justify-content: space-between; }
.general-popup-footer button { margin-bottom: 0; }
.general-popup-footer button svg { width: 20px; height: 20px; }

@media (max-width: 575px) {
	.gallery .general-popup-content img { width: auto; }
}
